import {
    apiDelete,
    toastError,
    toastSuccess,
    toastWarning,
} from '@/components/helper';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

export default function CompanyRemoveRequestForm({ company, onFinished }) {
    const toast = useToast();
    const {
        // register,
        handleSubmit,
        // watch,
        // reset,
        formState: { isSubmitting, errors },
        // setValue,
        // getValues,
    } = useForm({
        mode: 'onBlur',
    });

    // member request is pending
    let diffInHours = dayjs().diff(company.created_at, 'hours');
    if (diffInHours <= 24) {
        return memberRequestIsPending(toast);
    }

    const onSubmit = async formValues => {
        try {
            await apiDelete(`/${company.master_company_id}/membership/remove`);
            // await apiDelete(
            //     `/${company.master_company_id}/membership/user-delete`,
            // );
            onFinished();
            toastSuccess(toast, 'Mitgliedschaftsanfrage ist nun gelöscht');
        } catch (error) {
            toastError(toast, error);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InfoText />

            <Box textAlign="center">
                <Button
                    mt={8}
                    colorScheme="red"
                    isLoading={isSubmitting}
                    type="submit"
                    size="sm"
                >
                    Mitgliedschaftsanfrage löschen (cancel request)
                </Button>
            </Box>
        </form>
    );
}

function InfoText() {
    return (
        <Text fontSize="sm">
            Mitgliedschaftsanfrage hat funktioniert. Wir haben den Anbieter über
            die Anfrage informiert. Sobald du freigeschaltet bist, stehen dir
            weitere Funktionen zur Verfügung.
        </Text>
    );
}

function memberRequestIsPending(toast) {
    return (
        <Box>
            <InfoText />
            <Box textAlign="center">
                <Button
                    mt={8}
                    colorScheme="yellow"
                    color="gray.700"
                    type="submit"
                    onClick={() =>
                        toastWarning(
                            toast,
                            'Die Mitgliedschaftsanfrage wird vom Anbieter bearbeitet. Dieser Prozess kann bis zu 24 Stunden dauern.',
                        )
                    }
                >
                    Status: Warte auf eine Bestätigung
                </Button>
            </Box>
        </Box>
    );
}
