import ActiveLink from '@/components/ActiveLink';
import { useForm } from 'react-hook-form';
import {
    isDev,
    toastSuccess,
    toastError,
    apiCreate,
} from '@/components/helper';
import { Box, Button, useToast, Link } from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckboxField from './fields/CheckboxField';

const schema = yup.object().shape({
    privacy: yup
        .bool()
        .required('Die Datenschutzbedingungen müssen aktzeptiert werden')
        .oneOf([true], 'Die Datenschutzbedingungen müssen aktzeptiert werden'),
});

export default function CompanyCreateRequestForm({ company, onFinished }) {
    const toast = useToast();

    const {
        register,
        handleSubmit,
        // watch,
        reset,
        formState: { isSubmitting, errors },
        // setValue,
        // getValues,
    } = useForm({
        defaultValues: {},
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const onSubmit = async formValues => {
        try {
            await apiCreate(`/${company.master_company_id}/membership/${company.id}/request`);
            // let response = await makeRequest(
            //     '/companyMember/createMembershipRequest',
            //     {
            //         company_id: company.id,
            //     },
            // );

            onFinished();
            reset({});
            toastSuccess(toast, 'Mitgliedschaftsanfrage hat funktioniert');
        } catch (error) {
            toastError(toast, error);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <CheckboxField
                id="privacy"
                name="privacy"
                label={'Ich akzeptiere die '}
                content={
                    <ActiveLink
                        href="/datenschutz-artikel13"
                        activeClassName=""
                        includePath={false}
                    >
                        <Link ml="1" fontWeight="bold" color="brand.blue">
                            Datenschutzinformation nach Artikel 13 DSGVO
                        </Link>
                    </ActiveLink>
                }
                required={true}
                register={register}
                errors={errors}
            />

            <Box textAlign="center">
                <Button
                    mt={4}
                    colorScheme="brand"
                    isLoading={isSubmitting}
                    type="submit"
                >
                    Mitgliedschaft anfragen
                </Button>
            </Box>
        </form>
    );
}
