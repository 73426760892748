import { useRouter } from 'next/router';
import useSWR from 'swr';
import { apiGet } from '../helper';
// import dayjs from 'dayjs';
// import useAppointments from './useAppointments';

export default function useAppointmentCompanies(masterCompanyId) {
    const router = useRouter();
    let { companyId } = router.query;
    let path = masterCompanyId ? `/${masterCompanyId}/appointment/${companyId}` : null;

    const { data, isLoading, mutate, error } = useSWR(path, apiGet);
    return [data, isLoading, mutate, error];

    // let date = dayjs().startOf('hour').toDate();

    // let filter = {
    //     where: {
    //         company_id: {
    //             _eq: companyId,
    //         },
    //         appointment: { _gte: date },
    //         status: {
    //             _eq: 'ACTIVE',
    //         },
    //     },
    // };

    // let [appointments, loading, refetch, error] = useAppointments(filter);
    // return [appointments, loading, refetch, error];
}
