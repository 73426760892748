import { useState } from 'react';
import { useRouter } from 'next/router';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import {
    toastSuccess,
    toastError,
    apiUpdate,
    apiDelete,
} from '@/components/helper';
import { Flex, Button, useToast, Text } from '@chakra-ui/react';
import SettingsField from './fields/SettingsField';
import FormTitle from './fields/FormTitle';
import _ from 'lodash';

export default function CompanyUserSettingsForm({
    membership,
    refetchMembership,
}) {
    // prepare
    const router = useRouter();
    const toast = useToast();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    let company = membership.companies[0];

    return (
        <>
            <FormTitle>Terminansicht</FormTitle>
            <SettingsField
                id="hide_not_bookable_appointments"
                label="Nicht buchbare Termine ausblenden"
                initValue={membership.hide_not_bookable_appointments}
                onAlreadyChanged={(settingId, value) =>
                    save(toast, company.master_company_id, settingId, value)
                }
            />
            <FormTitle>Benachrichtigung</FormTitle>

            <SettingsField
                id="enable_reminder_push"
                label="Terminerinnerung via Push Notification"
                initValue={membership.enable_reminder_push}
                onAlreadyChanged={(settingId, value) =>
                    save(toast, company.master_company_id, settingId, value)
                }
            />
            <SettingsField
                id="enable_reminder_email"
                label="Terminerinnerung via E-Mail"
                initValue={membership.enable_reminder_email}
                onAlreadyChanged={(settingId, value) =>
                    save(toast, company.master_company_id, settingId, value)
                }
            />
            <SettingsField
                id="enable_waitlist_reminder_push"
                label="Wartelisten-Benachrichtigung via Push N."
                initValue={membership.enable_waitlist_reminder_push}
                onAlreadyChanged={(settingId, value) =>
                    save(toast, company.master_company_id, settingId, value)
                }
            />
            <SettingsField
                id="enable_waitlist_reminder_email"
                label="Wartelisten-Benachrichtigung via E-Mail"
                initValue={membership.enable_waitlist_reminder_email}
                onAlreadyChanged={(settingId, value) =>
                    save(toast, company.master_company_id, settingId, value)
                }
            />

            <FormTitle>Anbieter</FormTitle>

            <ConfirmDialog
                isOpen={isOpenDelete}
                onClose={() => setIsOpenDelete(false)}
                action={async () => {
                    setIsOpenDelete(false); // modal
                    setDeleteLoading(true);

                    try {
                        await apiDelete(
                            `/${company.master_company_id}/membership/user-delete`,
                        );
                        setDeleteLoading(false);
                        refetchMembership();
                        toastSuccess(toast, 'Anbieter wurde gelöscht');
                        router.push('/dashboard');
                    } catch (error) {
                        setDeleteLoading(false);
                        toastError(toast, error);
                    }
                }}
            />

            <Flex mt="1" justify="space-between" align="center">
                <Text mt="1" fontSize="sm" color="gray.500">
                    Du kannst den Anbieter / Mitgliedschaft löschen.
                </Text>

                <Button
                    w="48"
                    colorScheme="red"
                    isLoading={deleteLoading}
                    type="submit"
                    size="xs"
                    variant="outline"
                    onClick={() => setIsOpenDelete(true)}
                >
                    Anbieter Löschen
                </Button>
            </Flex>
        </>
    );
}

async function save(toast, masterId, settingId, value) {
    try {
        await apiUpdate(`/${masterId}/membership/user-settings`, {
            [settingId]: value,
        });
        toastSuccess(toast, 'Einstellung wurde gespeichert');
    } catch (error) {
        toastError(toast, error);
    }
}
